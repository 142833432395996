import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/i/:CID?/:QID?",
    name: "Invoice",
    component: () => import("@/views/Invoice.vue"),
  },
  {
    path: "/p/:CID?/:QID?",
    name: "Prescription",
    component: () => import("@/views/Prescription.vue"),
  },
  {
    path: "/f/:CID?/:QID?",
    name: "Feedback",
    component: () => import("@/views/Feedback.vue"),
  },
  {
    path: "/l/:CID?/:QID?",
    name: "LabOrder",
    component: () => import("@/views/LabOrder.vue"),
  },
  {
    path: "/success",
    name: "Success",
    component: () => import("@/views/Success.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
