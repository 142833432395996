import Vue from 'vue'
import App from './App.vue'

import '@/assets/css/tailwind.css'
import axios from "./axios.js";
Vue.prototype.$axios = axios;
import i18n from "./i18n";

import { drawTeethNumber } from "./utils/index";
Vue.prototype.$drawTeethNumber = drawTeethNumber;

import router from './router'
Vue.config.productionTip = false

// VeeValidate
// import VeeValidate from "vee-validate";
import VeeValidate, { Validator } from "vee-validate";
import ar from "vee-validate/dist/locale/ar.js";
import en from "vee-validate/dist/locale/en.js";
// Vue.use(VeeValidate);
// if (i18n.locale == "ar") {
  Validator.localize("ar", ar);
// } else {
//   Validator.localize("en", en);
// }
Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    en: en,
    ar: ar,
  },
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
